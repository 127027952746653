<script lang="ts" setup>
import { useProducts } from '@/composables'

import { ArrowLongRightIcon } from '@heroicons/vue/24/outline'

const { 
  products,
  fetchProducts
} = useProducts({
  first: 20,
  reverse: true,
  sortKey: 'CREATED_AT',
  query: 'inventory_total:>0 AND tag:christmas AND (NOT tag:christmascard) AND (NOT tag:pantry)'
})

fetchProducts()

const christmasProducts = computed(() => {
  return products.value.filter(product => {
    return product.featuredImage
  })
})
</script>

<template>
  <!-- Start Related Products -->
  <section
    v-if="products.length > 0"
    aria-labelledby="christmas-products" 
    class="py-8 border-gray-200 lg:border-t sm:px-0 pl-8"
  >
    <NuxtLink
      class="flex items-center justify-between pr-5"
      :to="{
        name: 'collections-collectionHandle',
        params: {
          collectionHandle: 'christmas-gifts',
        }
      }"
    >
      <h2 
        id="christmas-products" 
        class="text-white text-sm sm:text-base uppercase font-bold tracking-widest"
      >
        Christmas Gifts
      </h2>
      <ArrowLongRightIcon class="w-6 h-6 text-white" />
    </NuxtLink>

    <!-- Start Products New In Carousel Scroller -->
    <ProductsCarouselScroller 
      :products="christmasProducts"
    />
    <!-- End Products New In Carousel Scroller -->
  </section>
  <!-- End Related Products -->
</template>