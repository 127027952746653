<script lang="ts" setup>
import { useProducts } from '@/composables'

import { ArrowLongRightIcon } from '@heroicons/vue/24/outline'

const { 
  products,
  fetchProducts
} = useProducts({
  first: 10,
  reverse: false,
  query: 'inventory_total:>0 AND tag:garden AND NOT tag:greenandblue AND NOT tag:frond AND NOT tag:book'
})

fetchProducts()

const gardenProducts = computed(() => {
  return products.value.filter(product => {
    return product.featuredImage
  })
})
</script>

<template>
  <!-- Start Related Products -->
  <section
    v-if="products.length > 0"
    aria-labelledby="new-in-products" 
    class="py-8 border-gray-200 lg:border-t sm:px-0 pl-8"
  >
    <NuxtLink
      class="flex items-center justify-between pr-5"
      :to="{
        name: 'collections-collectionHandle',
        params: {
          collectionHandle: 'garden',
        }
      }"
    >
      <h2 
        id="the-book-nook-products" 
        class="text-white text-sm sm:text-base uppercase font-bold tracking-widest"
      >
        The Garden
      </h2>
      <ArrowLongRightIcon class="w-6 h-6 text-white" />
    </NuxtLink>

    <!-- Start Products New In Carousel Scroller -->
    <ProductsCarouselScroller 
      :products="gardenProducts"
    />
    <!-- End Products New In Carousel Scroller -->
  </section>
  <!-- End Related Products -->
</template>