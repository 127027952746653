<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'

definePageMeta({
  layout: false,
});

const { y } = useWindowScroll()

const show = computed(() => {
  return y.value < 200
})

const collections = [
  {
    handle: 'home',
  },
  {
    handle: 'garden',
  },
  {
    handle: 'books',
  },
  {
    handle: 'cards',
  },
  {
    handle: 'dogs',
  },
  {
    handle: 'vintage',
  },
  {
    handle: '1percent-for-the-planet',
  },
  {
    handle: 'b-corporation',
  },
  {
    handle: 'outdoor-living',
  },
  {
    handle: 'mindful-brands',
  },
  {
    handle: 'enriching-childrens-gifts'
  },
  {
    handle: 'inspirational-charities'
  }
]

const ITS_CHRISTMASSSSSSSSSSSSSSSS = computed(() => {
  const now = new Date()
  const christmasDay = new Date(now.getFullYear(), 11, 25);
  return now.getMonth() >= 10 && now.getTime() < christmasDay.getTime()
})
</script>

<template>
  <div class="bg-blue-100 md:min-h-screen">
    <NuxtLayout name="shop">
      <template #header>
        <div class="h-full flex flex-col justify-between" :class="{ '-z-100': !show }">
          <div class="h-full py-16 p-6 lg:p-16">
            <div class="flex flex-col justify-start">
              <div class="flex justify-center lg:justify-start mt-24 md:mt-28 lg:mt-0">
                <!-- Start Kind & Conscious Logo -->
                <h1 class="font-serif text-white sr-only">
                  Kind & Conscious
                </h1>
                <KindAndConsciousLogo
                  class="h-28 sm:h-32 w-auto text-white"
                />
                <!-- End Kind & Conscious Logo -->
              </div>
              <div class="w-full flex justify-center lg:justify-start mt-48 lg:mt-0 md:hidden">
                <div class="w-full grid grid-cols-2 px-3 gap-2">
                  <NuxtLink
                    class="w-full flex items-center justify-center bg-blue-800 bg-opacity-80 rounded-tl-2xl transition-all duration-300 ease-in-out"
                    :to="{
                      name: 'collections-collectionHandle',
                      params: {
                        collectionHandle: 'new-in',
                      }
                    }"
                  >
                    <h2  
                      class="w-full text-white text-center justify-center p-3.5 px-5 text-base uppercase font-semibold tracking-widest"
                    >
                      Shop New In
                    </h2>
                  </NuxtLink>

                  <NuxtLink
                    class="w-full flex items-center justify-center bg-blue-800 bg-opacity-80 rounded-br-2xl transition-all duration-300 ease-in-out"
                    :to="{
                      name: 'collections',
                    }"
                  >
                    <h2  
                      class="w-full text-white text-center p-4.5 px-5 text-base uppercase font-semibold tracking-widest"
                    >
                      Shop All
                    </h2>
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #main>
        <div 
          class="w-full absolute bottom-full mb-30"
          :class="{ 
            'opacity-100 z-10': show,
            'opacity-0 -z-100': !show
          }"
        >
          <div class="flex justify-center items-center animate-scroll">
            <span class="relative flex h-4 w-4">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-60"></span>
              <span class="relative inline-flex rounded-full h-4 w-4 bg-white opacity-80"></span>
            </span>
          </div>
        </div>

        <!-- Start Collections Items -->
        <CollectionList 
          :collections="collections"
        />
        <!-- End Collections Items -->

        <!-- Start Products Christmas Cards -->
        <div
          v-if="ITS_CHRISTMASSSSSSSSSSSSSSSS" 
          id="products-christmas-cards"
          class="w-full flex justify-center items-center bg-blue-700"
        >
          <div class="relative w-full h-full">
            <ProductsChristmasCards />
          </div>
        </div>
        <!-- End Products Christmas Cards -->

        <!-- Start Products Christmas -->
        <div
          v-if="ITS_CHRISTMASSSSSSSSSSSSSSSS" 
          id="products-christmas" 
          class="w-full flex justify-center items-center bg-blue-600"
        >
          <div class="relative w-full h-full">
            <ProductsChristmas/>
          </div>
        </div>
        <!-- End Products Christmas -->

        <!-- Start Products The Book Nook -->
        <div 
          id="products-the-book-nook"
          class="w-full flex justify-center items-center bg-blue-700"
        >
          <div class="relative w-full h-full">
            <ProductsTheBookNook />
          </div>
        </div>
        <!-- End Products The Book Nook -->

        <!-- Start Products Cards -->
        <div 
          id="products-plastic-free-cards" 
          class="w-full flex justify-center items-center bg-blue-200"
        >
          <div class="relative w-full h-full">
            <ProductsCards />
          </div>
        </div>
        <!-- End Products Cards -->

        <!-- Start Products The Garden -->
        <div 
          id="products-the-garden"
          class="w-full flex justify-center items-center bg-blue-700"
        >
          <div class="relative w-full h-full">
            <ProductsTheGarden />
          </div>
        </div>
        <!-- End Products The Garden -->
      </template>
    </NuxtLayout>
  </div>
</template>