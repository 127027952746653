<script lang="ts" setup>
import { useProducts } from '@/composables'

import { ArrowLongRightIcon } from '@heroicons/vue/24/outline'

const INVENTORY_TOTAL = 'inventory_total:>0'

const AVAILABLE_FOR_SALE = 'available_for_sale:true'

const BASE_QUERY = `${INVENTORY_TOTAL} AND ${AVAILABLE_FOR_SALE}`

const BASE_CARD_QUERY = `tag:plasticfreegreetingscard OR tag:card`

const today = new Date()

const query = computed(() => {
  // Valentine's Day:
  if (today > new Date(today.getFullYear(), 0, 1) && today < new Date(today.getFullYear(), 2, 14)) {
    return `${BASE_QUERY}  AND (${BASE_CARD_QUERY} OR tag:valentinescard)`
  }

  // [TBI] Mother's Day (4th Sunday in Lent every year):

  // [TBI] Father Day (3rd Sunday in June every year):

  // Christmas:
  if (today > new Date(today.getFullYear(), 10, 1) && today < new Date(today.getFullYear(), 12, 25)) {
    return `${BASE_QUERY} AND (${BASE_CARD_QUERY} OR tag:christmascard)`
  }

  return `${BASE_QUERY} AND (${BASE_CARD_QUERY}) AND NOT (tag:valentinescard OR tag:christmascard)`
})

const { 
  products,
  fetchProducts
} = useProducts({
  first: 20,
  reverse: true,
  sortKey: 'CREATED_AT',
  query: query
})

fetchProducts()

const cardsProducts = computed(() => {
  return products.value.filter(product => {
    return product.featuredImage
  })
})
</script>

<template>
  <!-- Start Related Products -->
  <section
    v-if="products.length > 0"
    aria-labelledby="new-in-products" 
    class="py-8 border-gray-200 lg:border-t sm:px-0 pl-8"
  >
    <NuxtLink
      class="flex items-center justify-between pr-5"
      :to="{
        name: 'collections-collectionHandle',
        params: {
          collectionHandle: 'plastic-free-greetings-cards',
        }
      }"
    >
      <h2 
        id="new-in-products" 
        class="text-blue-800 text-sm sm:text-base uppercase font-bold tracking-widest"
      >
        Plastic Free Cards
      </h2>
      <ArrowLongRightIcon class="w-6 h-6 text-blue-800" />
    </NuxtLink>

    <!-- Start Products New In Carousel Scroller -->
    <ProductsCarouselScroller 
      :dark="false"
      :products="cardsProducts"
    />
    <!-- End Products New In Carousel Scroller -->
  </section>
  <!-- End Related Products -->
</template>